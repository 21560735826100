<template>
  <div class="col col-100 nopad">
    <div id="module1" class="">
      <section
        id="step-start"
        v-if="step == 0"
        class="step"
        style="
          background-image: url('https://cdn2.jlrfrance.fr/uploads/10098/landrover-quiz.png');
        "
      >
        <div>
          <h2 class="heading_2" style="margin-bottom: 1em !important">
            Découvrez la motorisation qui vous correspond
          </h2>
          <p class="text_1">
            Parlez-nous de votre style de vie ou de conduite.<br />Et découvrez
            si votre véhicule idéal sera hybride, hybride rechargeable ou
            hybride Flexfuel.
          </p>
          <button @click="start" class="btn_1">Démarrez le test</button>
        </div>
      </section>
      <div v-if="step > 0" class="background-1 w100">
        <div class="w100 center" v-if="step <= totauxSteps">
          <span id="stepIndicatorContainer">
            <div class="indicator" :style="indicator"></div>
          </span>
          <p class="heading_5" v-if="step <= totauxSteps">
            {{ step }} sur {{ totauxSteps }}
          </p>
          <p class="heading_5" v-else>résultats</p>
        </div>
        <section v-if="step == 1" class="step">
          <h2 class="heading_2" v-html="questions[0]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer1-b" class="labelBlock text_1"
                >Je suis professionnel<input
                  type="radio"
                  id="answer1-b"
                  name="answer1"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[0].value"
              /></label>
              <label for="answer1-a" class="labelBlock text_1"
                >Je suis particulier
                <input
                  type="radio"
                  id="answer1-a"
                  name="answer1"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[0].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/QUIZ-1.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 2" class="step">
          <h2 class="heading_2" v-html="questions[1]"></h2>
          <div class="column" style="align-items: center">
            <div class="col col-30 col-m-20">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/QUIZ-2.png"
                alt=""
                style="margin-bottom: 3em"
              />
            </div>
            <Range></Range>
          </div>
        </section>
        <section v-if="step == 3" class="step">
          <h2 class="heading_2" v-html="questions[2]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer3-a" class="labelBlock text_1"
                >oui<input
                  type="radio"
                  id="answer3-a"
                  name="answer3"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[2].value"
              /></label>
              <label for="answer3-b" class="labelBlock text_1"
                >non<input
                  type="radio"
                  id="answer3-b"
                  name="answer3"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[2].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/QUIZ-3.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 4" class="step">
          <h2 class="heading_2" v-html="questions[3]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer4-a" class="labelBlock text_1"
                >Oui, je dois souvent circuler dans des zones à faibles
                émissions
                <input
                  type="radio"
                  id="answer4-a"
                  name="answer4"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[3].value"
              /></label>
              <label for="answer4-b" class="labelBlock text_1"
                >Non, pas particulièrement<input
                  type="radio"
                  id="answer4-b"
                  name="answer4"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[3].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/QUIZ-4.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 5" class="step">
          <h2 class="heading_2" v-html="questions[4]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer5-a" class="labelBlock text_1"
                >oui<input
                  type="radio"
                  id="answer5-a"
                  name="answer5"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[4].value"
              /></label>
              <label for="answer5-b" class="labelBlock text_1"
                >non<input
                  type="radio"
                  id="answer5-b"
                  name="answer5"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[4].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/QUIZ-5.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 6" class="step">
          <h2 class="heading_2" v-html="questions[5]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer6-a" class="labelBlock text_1"
                >Oui, pour le travail ou les loisirs<input
                  type="radio"
                  id="answer6-a"
                  name="answer6"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[5].value"
              /></label>
              <label for="answer6-b" class="labelBlock text_1"
                >Non, pas particulièrement<input
                  type="radio"
                  id="answer6-b"
                  name="answer6"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[5].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/QUIZ-6.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section id="result" v-if="step == 7" class="step">
          <h2 class="heading_2 title">{{ content[best1].result[0] }}</h2>
          <div class="margX2"></div>
          <div class="row">
            <div class="col col-50 col-m-33">
              <picture v-if="best1 == 0"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/PHEV_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
              <picture v-if="best1 == 1"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/MHEV_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
              <picture v-if="best1 == 2"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/FLEXFUEL_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
            </div>
            <div class="col col-100 col-m-66 left">
              <p class="heading_3 strong" v-html="content[best1].result[1]"></p>
              <p v-html="content[best1].result[2]"></p>
              <a :href="content[best1].result[3]" class="btn_4" target="_blank"
                >En savoir plus sur cette motorisation</a
              ><br />
              <a href="#module2" class="btn_4" target="_self"
                >Comparez avec nos autres motorisations</a
              >
            </div>
          </div>
          <div class="row">
            <p
              class="heading_2 w100"
              v-html="content[best1].result[4]"
              style="margin-bottom: 0.5em !important"
            ></p>
            <div
              class="col col-100 col-m-50 col-l-33 col-xl-25 padding left"
              v-for="(vehicle, index) in content[best1].vehicles"
              :key="index"
            >
              <img
                :src="
                  'https://cdn2.jlrfrance.fr/uploads/10098' + vehicle.picture
                "
                :alt="vehicle.name"
                class="w100"
              />
              <h4
                :class="{ 'nomarg-b': vehicle.info != undefined }"
                class="heading_3 strong"
              >
                {{ vehicle.name }}
              </h4>
              <p class="heading_4" v-html="vehicle.info"></p>
              <a :href="vehicle.link1" class="btn_4" target="_blank"
                >Découvrez ce modèle</a
              ><br />
              <a
                :href="vehicle.link2[0]"
                v-html="vehicle.link2[1]"
                class="btn_4"
                target="_blank"
              ></a>
            </div>
          </div>
        </section>
        <div class="row flex-between btns" v-if="step > 0 && step < 7">
          <p class="msg" v-show="notif.error == 1">{{ notif.disclaimer }}</p>
          <button
            v-if="step > 0 && step < 7"
            @click="prevStep"
            class="btn_1 icon-toleft"
          >
            précédent
          </button>
          <button
            v-if="step < 6 && step > 0"
            @click="nextStep(step)"
            class="btn_1 icon-toright"
          >
            suivant
          </button>
          <button
            v-if="step == totauxSteps"
            @click="calcul(step)"
            class="btn_1"
          >
            voir les résultats
          </button>
        </div>
        <div class="row btns flex-end" v-if="step == 7">
          <button @click="retry" class="btn_1">recommencer</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Range from "./Range.vue";
export default {
  name: "Quiz",
  components: {
    Range,
  },
  data() {
    return {
      questions: [
        "Êtes-vous un client professionnel ou particulier&nbsp;?",
        "Combien de kilomètres parcourez-vous en moyenne par jour&nbsp;?",
        "Avez-vous accès ou possibilité d'accès à un point de recharge à votre domicile ou sur votre lieu de travail&nbsp;?",
        "La classification de la vignette Crit'Air est-elle importante pour vous&nbsp;?",
        "Effectuez-vous fréquemment de longs trajets autoroutiers&nbsp;?",
        "La capacité de remorquage est-elle importante pour vous&nbsp;?",
      ],
      overlay: 0,
      step: 0,
      totauxSteps: 6,
      reponses: [0, 0, 0],
      best1: 0,
      best2: 0,
      notif: {
        error: 0,
        disclaimer: "* Veuillez sélectionner au moins une réponse.",
      },
      content: [
        {
          type: "PHEV",
          result: [
            "C'est le moment de passer à l'hybride rechargeable",
            "L'HYBRIDE RECHARGEABLE (PHEV)",
            "En associant un moteur thermique à un moteur électrique pour une efficacité et des performances optimales, la technologie hybride rechargeable s'adapte à tous vos trajets. Optez pour le tout-électrique en ville ou passez en mode thermique pour vos déplacements plus longs.<br>Ne faites ainsi aucun compromis et bénéficiez en plus de nombreux avantages fiscaux.",
            "https://www.landrover.fr/vehicles/phev/what-is-phev.html",
            "Démarrez aujourd'hui votre aventure avec nos modèles hybrides rechargeables",
          ],
          vehicles: [
            {
              name: "Range Rover Evoque",
              picture: "/landrover/PHEV_RRE.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover-evoque/hybrid.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=part_phev&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-phev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Discovery Sport",
              picture: "/landrover/PHEV_DS.jpg",
              link1:
                "https://www.landrover.fr/vehicles/discovery-sport/hybrid.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=part_phev&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-phev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Range Rover Sport",
              picture: "/landrover/PHEV_RRS.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover-sport/hybrid.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=part_phev&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-phev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Range Rover Velar",
              picture: "/landrover/PHEV_RRV.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover-velar/hybrid.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=part_phev&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-phev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Nouveau Range Rover",
              info: "Disponible à la commande début 2022.",
              picture: "/landrover/PHEV_RR-2.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover/hybrid.html",
              link2: [
                "https://lancement.nouveau-range-rover.fr/#/li/tmplA?utm_source=lrdx&utm_medium=external-link&utm_campaign=l460_launch_lettreIntention&utm_content=quizz-phev",
                "Pré-réservez votre véhicule",
              ],
            },
            {
              name: "Defender",
              picture: "/landrover/PHEV_DEF.jpg",
              link1: "https://www.landrover.fr/vehicles/defender/hybrid.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=part_phev&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-phev",
                "Recevez une offre commerciale",
              ],
            },
          ],
        },
        {
          type: "MHEV",
          result: [
            "La technologie hybride est faite pour vous",
            "L'HYBRIDE (MHEV)",
            "Alliance d'un moteur thermique et d'une batterie, la technologie hybride récupère et stocke l'énergie habituellement perdue lors du freinage, et la réutilise intelligemment pour assister le moteur. Elle vous permet ainsi de réduire votre consommation de carburant et les émissions CO₂.<br>Aucune recharge n'est nécessaire, le système s'occupe de tout : il ne vous reste plus qu'à profiter du plaisir de prendre la route en toute sérénité.",
            "https://www.landrover.fr/vehicles/range-rover-evoque/index.html#286-786910",
            "Démarrez aujourd'hui votre aventure avec nos modèles hybrides",
          ],
          vehicles: [
            {
              name: "Range Rover Evoque",
              picture: "/landrover/MHEV_RRE.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover-evoque/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/nouveau-range-rover-evoque?model=l551&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-mhev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Discovery Sport",
              picture: "/landrover/MHEV_DS.jpg",
              link1:
                "https://www.landrover.fr/vehicles/discovery-sport/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/discovery-sport?model=l550&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-mhev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Range Rover Sport",
              picture: "/landrover/MHEV_RRS.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover-sport/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/range-rover-sport?model=l494&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-mhev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Range Rover Velar",
              picture: "/landrover/MHEV_RRV.jpg",
              link1:
                "https://www.landrover.fr/vehicles/range-rover-velar/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/range-rover-velar?model=l560&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-mhev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Nouveau Range Rover",
              picture: "/landrover/MHEV_RR-2.jpg",
              link1: "https://www.landrover.fr/vehicles/range-rover/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/l460?model=l460&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-phev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Defender",
              picture: "/landrover/MHEV_DEF.jpg",
              link1: "https://www.landrover.fr/vehicles/defender/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/nouveau-defender?model=l663&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-mhev",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Discovery",
              picture: "/landrover/MHEV_DISCO.jpg",
              link1: "https://www.landrover.fr/vehicles/discovery/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation/nouveau-discovery?model=l462&formCode=lr-requestquote-fr-fr&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-mhev",
                "Recevez une offre commerciale",
              ],
            },
          ],
        },
        {
          type: "E85",
          result: [
            "L'hybride Flexfuel est faite pour vous",
            "L'HYBRIDE FLEXFUEL",
            "La technologie hybride Flexfuel vous permet désormais de rouler au superéthanol E85. En plus de son tarif très avantageux, vous bénéficiez également de nombreux avantages fiscaux, comme l'éxonération de malus à l'achat. Notre technologie vous assure une grande flexibilité, vous avez la possibilité de passer librement du superéthanol E85 à tout autre carburant essence (SP95, SP98 ou SP95-E10) et ce, à tout moment.",
            "https://www.landrover.fr/vehicles/flexfuel/index.html",
            "Démarrez aujourd'hui votre aventure avec nos véhicules hybrides flexfuel",
          ],
          vehicles: [
            {
              name: "Range Rover Evoque",
              picture: "/landrover/E85_RRE.jpg",
              link1: "https://www.landrover.fr/vehicles/flexfuel/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=flexfuel&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-flexfuel",
                "Recevez une offre commerciale",
              ],
            },
            {
              name: "Discovery Sport",
              picture: "/landrover/E85_DS.jpg",
              link1: "https://www.landrover.fr/vehicles/flexfuel/index.html",
              link2: [
                "https://www2.contact-landrover.fr/demande-de-cotation-2?formCode=lr-requestquote-fr-fr&variant=flexfuel&tracking_source=lrdx&tracking_medium=external-link&tracking_campaign=LRDX_quote&tracking_content=quizz-flexfuel",
                "Recevez une offre commerciale",
              ],
            },
          ],
        },
      ],
      Quiz: [
        {
          // pro ou perso
          value: "",
          phev: [2, 3],
          mhev: [1, 2],
          e85: [4, 1],
        },
        {
          // km journaliers
          value: 0,
          phev: [3, 1, 2, 0],
          mhev: [1, 2, 4, 0],
          e85: [2, 3, 3, 0],
        },
        {
          // accès point recharge électrique
          value: "",
          phev: [3, 2],
          mhev: [1, 4],
          e85: [1, 4],
        },
        {
          // crit'Air
          value: "",
          phev: [3, 0],
          mhev: [1, 0],
          e85: [2, 0],
        },
        {
          // Autoroute
          value: "",
          phev: [2, 0],
          mhev: [4, 0],
          e85: [3, 0],
        },
        {
          // Remorquage
          value: "",
          phev: [2, 0],
          mhev: [4, 0],
          e85: [3, 0],
        },
      ],
    };
  },
  methods: {
    start() {
      this.step = 1;
    },
    verifError(val) {
      if (val !== "") {
        this.notif.error = 0;
        return true;
      } else {
        this.notif.error = 1;
        return false;
      }
    },
    nextStep(val) {
      val = val - 1;
      if (this.verifError(this.Quiz[val].value) == true) {
        this.step++;
      }
    },
    prevStep() {
      this.step--;
    },
    retry() {
      this.Quiz.forEach((element) => {
        element.value = "";
      });
      this.Quiz[1].value = 0;
      this.step = 1;
    },
    calcul(val) {
      val = val - 1;
      if (this.verifError(this.Quiz[val].value) == true) {
        this.overlay = 1;
        this.step = 7;
        //let a = this.reponses;
        let totaux = [
          this.Quiz[0].phev[this.Quiz[0].value] +
            this.Quiz[1].phev[this.Quiz[1].value] +
            this.Quiz[2].phev[this.Quiz[2].value] +
            this.Quiz[3].phev[this.Quiz[3].value] +
            this.Quiz[4].phev[this.Quiz[4].value] +
            this.Quiz[5].phev[this.Quiz[5].value],
          this.Quiz[0].mhev[this.Quiz[0].value] +
            this.Quiz[1].mhev[this.Quiz[1].value] +
            this.Quiz[2].mhev[this.Quiz[2].value] +
            this.Quiz[3].mhev[this.Quiz[3].value] +
            this.Quiz[4].mhev[this.Quiz[4].value] +
            this.Quiz[5].mhev[this.Quiz[5].value],
          this.Quiz[0].e85[this.Quiz[0].value] +
            this.Quiz[1].e85[this.Quiz[1].value] +
            this.Quiz[2].e85[this.Quiz[2].value] +
            this.Quiz[3].e85[this.Quiz[3].value] +
            this.Quiz[4].e85[this.Quiz[4].value] +
            this.Quiz[5].e85[this.Quiz[5].value],
        ];
        let rep1 = Math.max(totaux[0], totaux[1], totaux[2]);
        let indice1 = totaux.findIndex((total) => total === rep1);
        this.best1 = indice1;
      }
    },
  },
  computed: {
    indicator() {
      let a = (this.step / this.totauxSteps) * 100;
      return "width: " + a + "%";
    },
  },
};
</script>
<style scoped>
.msg {
  font-size: 1em;
  text-transform: uppercase;
  margin: 0;
  padding-top: 0;
  padding-bottom: 1rem;
}
.msg:not(.hide) {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.step .row {
  width: 100%;
}
</style>