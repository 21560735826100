<template>
  <div id="module2" class="row center">
    <div class="margX2"></div>
    <div class="col col-100 padding">
      <h2 class="heading_2 center">{{ title }}</h2>
    </div>
    <div class="margX2"></div>
    <div class="comparatorContainer">
      <Card v-if="content[0].isActive" :content="content[0]"></Card>
      <Card v-if="content[1].isActive" :content="content[1]"></Card>
      <Card v-if="content[2].isActive" :content="content[2]"></Card>
    </div>
  </div>
</template>
<script>
import Card from "./Card.vue";
export default {
  name: "Comparator",
  components: {
    Card,
  },
  props: ["recoMotors"],
  data() {
    return {
      title: "Comparez nos motorisations",
      subtitle: "Sélectionnez jusqu'à 3 technologies",
      disclaimer: 0,
      toCompare: this.recoMotors,
      activeFilterLength: 0,
      content: [
        {
          filters: true,
          isActive: false,
          name: "PHEV",
          infos: {
            header: { picto: "PHEV_ICON.svg", titre: "Hybride rechargeable" },
            criteres: [
              "Électrique + essence",
              "Prise électrique : conseillée",
              "Entre 44 et 88 g",
              "Pas de malus<br>Carte grise gratuite<br>Pas de TVS<br>Plafond amortissement réhaussé",
              "Vignette 1",
              "Poids tractable : 1600 à 2500 kg",
              "Jusqu'à 6 personnes assises",
            ],
            links: [
              "https://www.landrover.fr/vehicles/phev/what-is-phev.html",
              "",
            ],
          },
        },
        {
          filters: true,
          isActive: false,
          name: "MHEV",
          infos: {
            header: { picto: "MHEV_ICON.svg", titre: "Hybride" },
            criteres: [
              "Diesel",
              "Prise électrique : non",
              "Entre 169 et 259 g",
              "Malus<br>Carte grise gratuite (sauf exception)<br>TVS",
              "Vignette 2",
              "Poids tractable : 2000 à 3500 kg",
              "Jusqu'à 7 personnes assises",
            ],
            links: [
              "https://www.landrover.fr/vehicles/range-rover-evoque/index.html#286-786910",
              "",
            ],
          },
        },
        {
          filters: true,
          isActive: false,
          name: "E85",
          infos: {
            header: { picto: "FLEXFUEL_ICON.svg", titre: "Flexfuel" },
            criteres: [
              "E85 / Essence",
              "Prise électrique : non",
              "Entre 196 et 232 g",
              "Pas de malus<br>Carte grise gratuite<br>TVS",
              "Vignette 1",
              "Poids tractable : 1800 à 2000 kg",
              "5+2 personnes assises",
            ],
            links: [
              "https://www.landrover.fr/vehicles/flexfuel/index.html",
              "",
            ],
          },
        },
        {
          filters: false,
          isActive: false,
          name: "Non défini",
          texte: "Comparer<br>une nouvelle motorisation",
        },
      ],
    };
  },
  methods: {
    addMotorToCompare(value) {
      if (this.activeFilterLength >= 3) {
        if (this.content[value].isActive === true) {
          this.content[value].isActive = !this.content[value].isActive;
          this.activeFilterLength--;
          this.disclaimer = 0;
        } else {
          this.disclaimer = 1;
        }
      } else {
        this.content[value].isActive = !this.content[value].isActive;
        if (this.content[value].isActive === true) {
          this.activeFilterLength++;
        } else {
          this.activeFilterLength--;
        }
      }
    },
    removeMotorToCompare(value) {
      this.content[value].isActive = !this.content[value].isActive;
      this.activeFilterLength--;
    },
    resetComparator() {
      const a = this.content;
      a.forEach((element) => {
        element.isActive = false;
        this.activeFilterLength = 0;
      });
    },
    comparisonAfterQuiz() {
      this.resetComparator();
      const a = this.toCompare;
      a.forEach((element) => {
        this.content[element].isActive = true;
        this.activeFilterLength++;
      });
    },
  },
  computed: {
    trueContent() {
      const a = this.content.filter((item) => item.filters == true);
      return a;
    },
  },
  watch: {
    recoMotors: function () {
      this.comparisonAfterQuiz();
    },
  },
  mounted() {
    this.comparisonAfterQuiz();
  },
};
</script>